import { ActivatedRoute } from "@angular/router"
import { AddressComponent } from "ngx-google-places-autocomplete/objects/addressComponent"
import type { ChoicesResponse } from "app/services/referral"
import { Component } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import type { INgxSelectOption } from "ngx-select-ex"
import { OnDestroy } from "@angular/core"
import { OnInit } from "@angular/core"
import { ResponseStatus } from "app/enums/response-status"
import { Router } from "@angular/router"
import { ServiceReferral } from "app/services/referral"
import { ServiceReferralImage } from "app/services/referral-image"
import { ServiceUser } from "app/services/user"
import { Subject } from "rxjs"
import { ToastrService } from "ngx-toastr"
import { Validators } from "@angular/forms"

@Component({ styleUrls: ["index.scss"], templateUrl: "index.html" })
export class RouteReferralComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  dragover = false

  formGroup = this.formBuilder.group({
    id: [null],
    ext_practice: [null, [Validators.required]],
    ext_user: [null, [Validators.required]],
    date: [null, [Validators.required]],
    is_completed: [null],
    ext_patient: this.formBuilder.group({
      first_name: [null, [Validators.required, Validators.minLength(2)]],
      last_name: [null, [Validators.required, Validators.minLength(2)]],
      home_phone: [null, [Validators.pattern(/^[\d]{10}$/)]],
      mobile_phone: [null, [Validators.pattern(/^[\d]{10}$/)]],
      email: [null, [Validators.email]],
      birth_date: [null, [Validators.required]],
      patient_address_street_1: [null],
      patient_address_street_2: [null],
      patient_address_city: [null],
      patient_address_state: [null, [Validators.pattern(/^[A-Z]{2}$/)]],
      patient_address_zip_code: [null, [Validators.pattern(/^[\d]{5}$/)]],
    }),
    referred_for: this.formBuilder.group({
      consultation_and_diagnosis: [null],
      root_canal_treatment: [null],
      re_treatment: [null],
      leave_post_space: [null],
      apicoectomy_retrograde: [null],
      apicoectomy_retrofilling: [null],
      pulp_exposure: [null],
      remove_post: [null],
      buildup: [null],
      post_and_crown_buildup: [null],
      tooth_is_open_for_drainage: [null],
      endodontic_microsurgery: [null],
      please_send_additional_referral_pads: [null],
      please_call_patient_to_arrange_appt: [null],
      patient_will_call_you_to_arrange_appt: [null],
      crown_or_bridge_is_cemented: [null],
      please_call_me: [null],
      xray: [null],
      tooth_numbers: this.formBuilder.group(
        new Array(32).fill([null]).reduce((ret, val, key) => {
          ret[key + 1] = val
          return ret
        }, {}),
      ),
      description: [null],
    }),
    images: [[]],
  })

  isBeignCreated: Boolean = false
  isBeignUpdated: Boolean = false

  loading = 0

  refP7sItems: ChoicesResponse["practices"] = []
  refU3sItems: ChoicesResponse["practices"][number]["users"] = []

  user = this.serviceUser.current.getValue()

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private serviceReferral: ServiceReferral,
    private serviceReferralImage: ServiceReferralImage,
    private serviceUser: ServiceUser,
    private toastr: ToastrService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.params.subscribe({
      next: ({ referralId: id }) => {
        this.isBeignCreated = id === "new"
        this.isBeignUpdated = id !== "new"
        this.loading++
        this.serviceReferral.choices(id).subscribe({
          next: ({ practices }) => {
            this.loading--
            this.refP7sItems = practices
            if (this.isBeignUpdated) {
              this.loading++
              this.serviceReferral.retrieve(id).subscribe({
                next: (resp) => {
                  this.loading--
                  this.formGroup.patchValue(resp)
                  const { ext_practice, ext_user } = resp
                  if (this.user.id !== ext_user) {
                    const found = practices.find(
                      (practice) => ext_practice === practice.id && practice.can_create_referrals_on_behalf_of_others,
                    )
                    if (!found) {
                      this.formGroup.disable()
                    }
                  }
                },
                error: (resp) => {
                  this.loading--
                  this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
                  throw new Error(resp)
                },
              })
            }
          },
          error: (resp) => {
            this.loading--
            this.toastr.error("We will investigate the problem.", `${resp.statusText}`)
            throw new Error(resp)
          },
        })
      },
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  onAddressChanged({ address_components }: { address_components: AddressComponent[] }) {
    const data = {
      patient_address_street_1: "",
      patient_address_street_2: "",
      patient_address_city: "",
      patient_address_state: "",
      patient_address_zip_code: "",
    }
    address_components.forEach((cmp) => {
      if (cmp.types.includes("administrative_area_level_1")) {
        data["patient_address_state"] = cmp.short_name
      } else if (cmp.types.includes("locality")) {
        data["patient_address_city"] = cmp.short_name
      } else if (cmp.types.includes("postal_code")) {
        data["patient_address_zip_code"] = cmp.short_name
      } else if (cmp.types.includes("route")) {
        if (data["patient_address_street_1"]) {
          data["patient_address_street_1"] = data["patient_address_street_1"] + ", " + cmp.short_name
        } else {
          data["patient_address_street_1"] = cmp.short_name
        }
      } else if (cmp.types.includes("street_number")) {
        if (data["patient_address_street_1"]) {
          data["patient_address_street_1"] = cmp.short_name + ", " + data["patient_address_street_1"]
        } else {
          data["patient_address_street_1"] = cmp.short_name
        }
      }
    })
    this.formGroup.get("ext_patient")!.patchValue(data)
  }

  onImagesAdded(files: File[]) {
    const id = this.formGroup.get("id")!.value
    if (id) {
      this.serviceReferralImage.create(files.splice(0), { referral: this.formGroup.get("id")?.value }).subscribe({
        next: (resp) => {
          this.formGroup.get("images")!.value.push(resp)
        },
      })
    }
  }

  onImagesClicked(action: "delete" | "download" | "view", image: any) {
    if (action === "delete") {
      this.serviceReferralImage.delete(image.id).subscribe({
        next: () => {
          const images: any[] = this.formGroup.get("images")!.value
          const index = images.indexOf(image)
          if (index !== -1) {
            images.splice(index, 1)
          }
        },
      })
    }
    if (action === "download") {
      this.serviceReferralImage.download(image.id).subscribe()
    }
    if (action === "view") {
      this.serviceReferralImage.view(image.id).subscribe()
    }
  }

  onSelectionChangedRefP7sItems([opt]: INgxSelectOption[]) {
    const { data }: { data: ChoicesResponse["practices"][number] } = opt
    if (this.formGroup.enabled) {
      if (!opt.data.can_create_referrals_on_behalf_of_others) {
        const user = data.users.find((user) => user.id === this.user.id)
        if (user) {
          this.refU3sItems = [user]
        } else {
          this.refU3sItems = []
        }
      } else {
        this.refU3sItems = opt.data.users
      }
    } else {
      this.refU3sItems = opt.data.users
    }
  }

  onSubmit() {
    if (this.isBeignCreated || this.isBeignUpdated) {
      this.loading++
      if (this.isBeignCreated) {
        this.serviceReferral.create(this.formGroup.value).subscribe({
          next: (resp) => {
            this.loading--
            this.toastr.success("Referral Created", "Done!")
            this.router.navigate(["..", resp.id], { relativeTo: this.route })
          },
          error: (resp) => this.handleSubmitError(resp),
        })
      }
      if (this.isBeignUpdated) {
        this.serviceReferral.update(this.formGroup.value).subscribe({
          next: (resp) => {
            this.loading--
            this.formGroup.patchValue(resp)
            this.toastr.success("Referral Updated", "Done!")
          },
          error: (resp) => this.handleSubmitError(resp),
        })
      }
    }
  }

  trackById(_index: number, item: any) {
    return item.id
  }

  private handleSubmitError(resp: any) {
    const {
      error: {
        errors = [], //
        ext_patient = null,
        ...fields
      },
      status,
      statusText,
    } = resp
    // console.error(JSON.stringify({ status, statusText, errors, ext_patient, fields }, null, 2)) //-> only-for-testing
    if (status === ResponseStatus.BAD_REQUEST) {
      if (ext_patient) {
        const g = this.formGroup.get("ext_patient")
        if (g) {
          for (let field in ext_patient) {
            const f = g.get(field)
            if (f) {
              f.setErrors({ ...f.errors, server: ext_patient[field] })
            }
          }
        }
      }
      for (let field in fields) {
        const f = this.formGroup.get(field)
        if (f) {
          f.setErrors({ ...f.errors, server: fields[field] })
        }
      }
      this.formGroup.setErrors({ ...this.formGroup.errors, server: errors })
    } else {
      this.toastr.error("We will investigate the problem.", `${statusText}`)
      throw new Error(resp)
    }
  }
}
